import React from "react"
import LayoutRoot from "../components/LayoutRoot"
import Background from "../components/reusable/Background"
import { FlexContainer } from "../styles/layout.styled"
import { P } from "../styles/shared.styled"
import { HEADER_BG } from "../constants"
// import OneWork from "../components/OurWork/Work/OneWork"

const Work = ({ pageContext }) => {
  return (
    <>
      <LayoutRoot background={HEADER_BG} withFooter={false}>
        <Background bgUrl={pageContext?.workCover?.file?.url} height="350px">
          <FlexContainer flexDirection="column" padding="150px 0 0">
            <P
              margin="0 0 10px"
              color="white"
              fontSize="24px"
              fontWeight="bold"
            >
              {pageContext?.workTitle?.toUpperCase()}
            </P>
            <P color="white" fontSize="18px">
              {pageContext?.workCategory?.toUpperCase()}
            </P>
          </FlexContainer>
        </Background>
        {/*<OneWork />*/}
      </LayoutRoot>
    </>
  )
}

export default Work
